import React from 'react';
import {
    MDBFooter,
    MDBContainer,
    MDBCol,
    MDBRow,
    MDBIcon,
    MDBBtn
} from 'mdb-react-ui-kit';

export default function BottomFooter() {
    return (
        <MDBFooter className='text-center text-white' style={{ backgroundColor: 'gray', height: '60px' }}>
            <MDBContainer className='pt-4'>
                <section className='mb-4'>
                    <MDBBtn
                        rippleColor="dark"
                        color='link'
                        floating
                        size="lg"
                        className='text-dark m-1'
                        href='#!'
                        role='button'
                    >
                        <MDBIcon fab className='fab fa-facebook-f' />
                    </MDBBtn>

                    <MDBBtn
                        rippleColor="dark"
                        color='link'
                        floating
                        size="lg"
                        className='text-dark m-1'
                        href='#!'
                        role='button'
                    >
                        <MDBIcon fab className='fa-twitter' />
                    </MDBBtn>

                    <MDBBtn
                        rippleColor="dark"
                        color='link'
                        floating
                        size="lg"
                        className='text-dark m-1'
                        href='#!'
                        role='button'
                    >
                        <MDBIcon fab className='fa-google' />
                    </MDBBtn>

                    <MDBBtn
                        rippleColor="dark"
                        color='link'
                        floating
                        size="lg"
                        className='text-dark m-1'
                        href='#!'
                        role='button'
                    >
                        <MDBIcon fab className='fa-instagram' />
                    </MDBBtn>

                    <MDBBtn
                        rippleColor="dark"
                        color='link'
                        floating
                        size="lg"
                        className='text-dark m-1'
                        href='#!'
                        role='button'
                    >
                        <MDBIcon fab className='fa-linkedin' />
                    </MDBBtn>

                    <MDBBtn
                        rippleColor="dark"
                        color='link'
                        floating
                        size="lg"
                        className='text-dark m-1'
                        href='#!'
                        role='button'
                    >
                        <MDBIcon fab className='fa-github' />
                    </MDBBtn>
                </section>
            </MDBContainer>

            <div className='text-center text-dark p-3' style={{ backgroundColor: 'rgba(0, 0, 0, 0.2)', color: 'white', height: '60px', display: 'flex', flexDirection: 'column' }}>
                Contact info
                <a className='text-dark'>
                    adnancobanoglu8@gmail.com
                </a>
            </div>
        </MDBFooter>
    );
}