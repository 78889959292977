import React, { useState } from 'react';
import logo from './logo.svg';
import './App.css';
import Card from 'react-bootstrap/Card';
import ReactDOM from "react-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faArrowRight, faArrowTrendUp, faArrowUpRightDots, faChair, faMoneyBillTrendUp, faTable } from '@fortawesome/free-solid-svg-icons';
import BottomFooter from './footer';

function App() {
  const ProfileImage = require('./ProfileImage2.png');

  const [activeCard, setActiveCard] = useState(null);
  const [cardDescription, setCardDescription] = useState('');
  const [showPopup, setShowPopup] = useState(false);

  const handleCardClick = (card, description) => {
    setActiveCard(card);
    setCardDescription(description);
    setShowPopup(true);

    const handleGitHubClick = () => {
      window.location.replace('https://github.com/ocobanoglu18?tab=repositories');
    };
  };



  return (
    <div className="App">
      <header className="App-header">
        <div className="profile-container">
          <h1>
            <FontAwesomeIcon icon="fa-brands fa-instagram" />
          </h1>
          <img src={ProfileImage} alt="Profile" className="profile-image" />
        </div>

        <div className="Fener-Header">
          <p>
            <p>
              Koc University Computer Engineering Graduate | iOS & Software Developer
            </p>
          </p>
        </div>

        <div className='card-container'>
          <h3>Schools</h3>
          <div className="section">
            <div
              className={`card ${activeCard === 'Ted Ankara College' ? 'active' : ''}`}
              onClick={() => handleCardClick('Ted Ankara College', 'Description for Ted Ankara College')}
            >
              <div className="card-content">
                <h3>Ted Ankara College</h3>
                <img
                  src="https://www.tedankara.k12.tr/yeni/img/tedmezder.png"
                  alt="new"
                  style={{ maxWidth: '50px', maxHeight: '50px' }}
                />
              </div>
            </div>

            <div
              className={`card ${activeCard === 'LSE' ? 'active' : ''}`}
              onClick={() => handleCardClick('LSE', 'Description for LSE')}
            >
              <div className="card-content">
                <h3>LSE</h3>
                <img
                  src="https://www.iedp.com/media/4427/1200px-lse_logosvg.png"
                  alt="new"
                  style={{ maxWidth: '40px', maxHeight: '40px' }}
                />
              </div>
            </div>

            <div
              className={`card ${activeCard === 'Koç Üniversity' ? 'active' : ''}`}
              onClick={() => handleCardClick('Koç Üniversity', 'Description for Koç Üniversity')}
            >
              <div className="card-content">
                <h3>Koç Üniversity</h3>
                <img
                  src="https://logowik.com/content/uploads/images/koc-universitesi4302.jpg"
                  alt="new"
                  style={{ maxWidth: '60px', maxHeight: '60px' }}
                />
              </div>
            </div>
          </div>



          <h3>Internships</h3>
          <div className="section">
            <div className='card'>
              <div className="card-content">
                <h3>Vakıfbank</h3>
                <p>Software Developer</p>
              </div>
            </div>

            <div className='card'>
              <div className="card-content">
                <h3>Avokad.io</h3>
                <p>
                  Software Developer</p>
              </div>
            </div>

            <div className="card">
              <div className="card-content">
                <h3>CocaCola-İçecek</h3>
                <p>
                  Software Engineer</p>
              </div>
            </div>

            <div className="card">
              <div className="card-content">
                <h3>TüvTürk</h3>
                <p>IT Intern</p>
              </div>
            </div>
          </div>

          <div className="section">
            <div className="card">
              <div className="card-content">
                <h3>Related Digital</h3>
                <p>
                  Long Term Software Developer</p>
              </div>
            </div>

            <div className="card">
              <div className="card-content">
                <h3>YapıKredi Technology</h3>
                <p>iOS Developer</p>
              </div>
            </div>

            <div className="card">
              <div className="card-content">
                <h3>Simon-Kucher & Partners</h3>
                <p>Associate Consultant & Data Scientist</p>
              </div>
            </div>
          </div>


        </div>
        <div className="Fener-Header">
          <p>

          </p>
        </div>
        <h3>
          Projects
        </h3>

        <div className="card">
          <div className="card-content">
            <h3>iOS Projects</h3>
            <p>Developed mobile applications with UIKit and SwiftUI</p>
          </div>
        </div>
        <div className="card">
          <div className="card-content">
            <h3>Web Projects</h3>
            <p>Developed web applications with ReactJS</p>
          </div>
        </div>
        <div className="card">
          <div className="card-content">
            <h3>Android & iOS Projects</h3>
            <p>Developed mobile applications with Flutter and React Native</p>
          </div>
        </div>
        <div className="Fener-Header">
          <p>
            <p>

            </p>
          </p>
        </div>
        <h3>
          Personal Accounts
        </h3>
        <a href="https://www.linkedin.com/in/adnancobanoglu" target="_blank" rel="noopener noreferrer">
          <button className='linkedin-button'>
            linkedin
          </button>
        </a>

        <a href="https://adnancobanoglu.app" target="_blank" rel="noopener noreferrer">
          <button className='linkedin-button'>
            Personal Page
          </button>
        </a>

        <a href="https://github.com/ocobanoglu18?tab=repositories" target="_blank" rel="noopener noreferrer">
          <button className='linkedin-button'>
            GitHub
          </button>
        </a>

        {/* <div className="buttons-container">
          <button
            className={`section-button ${activeSection === 'section1' ? 'active' : ''}`}
            onClick={() => handleButtonClick('section1')}
          >
            Section 1
          </button>
          <button
            className={`section-button ${activeSection === 'section2' ? 'active' : ''}`}
            onClick={() => handleButtonClick('section2')}
          >
            Section 2
          </button>
          <button
            className={`section-button ${activeSection === 'section3' ? 'active' : ''}`}
            onClick={() => handleButtonClick('section3')}
          >
            Section 3
          </button>
        </div>

        <div className="sections-container">
          <div className={`section ${activeSection === 'section1' ? 'active' : ''}`}>
            <h2>Section 1</h2>
            <p>Content for section 1 goes here.</p>
          </div>

          <div className={`section ${activeSection === 'section2' ? 'active' : ''}`}>
            <h2>Section 2</h2>
            <p>Content for section 2 goes here.</p>
          </div>

          <div className={`section ${activeSection === 'section3' ? 'active' : ''}`}>
            <h2>Section 3</h2>
            <p>Content for section 3 goes here.</p>
          </div>
        </div> */}
        {showPopup && (
          <div className="popup">
            <div className="popup-content">
              <h3>{activeCard}</h3>
              <p>{cardDescription}</p>
              <button onClick={() => setShowPopup(false)}>Close</button>
            </div>
          </div>
        )}
      </header >

      <BottomFooter />
    </div >
  );
}

export default App;
